<template>
  <div>
    <base-card>
      <v-card-title>Personal Details</v-card-title>
      <v-card-text>
        <!-- Row -->
        <div class="border-bottom pb-3 mb-3">
          <v-row>
            <v-col :cols="12" :md="6">
              <label class="form-label text-bold" for="name">Name</label>
              <div>{{ investor.name }}</div>
            </v-col>
            <v-col :cols="12" :md="6">
              <label class="form-label text-bold" for="email">Email</label>
              <div>{{ investor.email }}</div>
            </v-col>
          </v-row>
        </div>
        <!-- / Row -->

        <!-- Row -->
        <div class="border-bottom pb-3 mb-3">
          <v-row>
            <v-col :cols="12" :md="6">
              <label class="form-label text-bold">Phone Number</label>
              <div>{{ investor.phone_number }}</div>
            </v-col>
            <v-col :cols="12" :md="6">
              <label class="form-label text-bold">Job Title</label>
              <div>{{ investor.job_title }}</div>
            </v-col>
          </v-row>
        </div>
        <!-- / Row -->

         <!-- Row -->
         <div class="">
          <v-row>
            <v-col :cols="12" :md="6">
              <label class="form-label text-bold">ID Verified</label>
              <div>{{ investor.id_verified ? 'Yes' : 'No' }}</div>
            </v-col>
          </v-row>
        </div>
        <!-- / Row -->
      </v-card-text>
    </base-card>

    <base-card class="mt-7">
      <v-card-title>Address</v-card-title>
      <v-card-text>
        <!-- Row -->
        <div class="border-bottom pb-3 mb-3">
          <v-row>
            <v-col :cols="12" :md="6">
              <label class="form-label text-bold">Address Line One</label>
              <div>{{ investor.address_line_one }}</div>
            </v-col>
            <v-col :cols="12" :md="6">
              <label class="form-label text-bold">Address Line Two</label>
              <div>{{ investor.address_line_two ? investor.address_line_two : '-' }}</div>
            </v-col>
          </v-row>
        </div>
        <!-- / Row -->

        <!-- Row -->
        <div class="border-bottom pb-3 mb-3">
          <v-row>
            <v-col :cols="12" :md="6">
              <label class="form-label text-bold">City</label>
              <div>{{ investor.city }}</div>
            </v-col>
            <v-col :cols="12" :md="6">
              <label class="form-label text-bold">Postcode</label>
              <div>{{ investor.postcode }}</div>
            </v-col>
          </v-row>
        </div>
        <!-- / Row -->

         <!-- Row -->
         <div class="">
          <v-row>
            <v-col :cols="12" :md="6">
              <label class="form-label text-bold">Country</label>
              <div>{{ investor.country }}</div>
            </v-col>
          </v-row>
        </div>
        <!-- / Row -->
      </v-card-text>
    </base-card>
  </div>
</template>

<script>
export default {
  props: ['investor']
}
</script>

<style scoped lang="scss">
.border-bottom {
  border-bottom: 1px solid #efecf3 !important;
}

.text-bold,
.fw-bold {
  font-weight: 700;
}
</style>