<template>
    <div class="approvals-show">
        <v-container fluid>
            <div class="welcome">
                <v-btn @click.prevent.native="goBack" icon color="blue">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>

                <h1>{{ title }}</h1>

                <div class="actions">
                    <v-btn color="primary" @click.prevent="attemptingToApprove = true">
                        <v-icon left>mdi-check</v-icon>
                        Approve
                    </v-btn>
                </div>
            </div>

            <!-- Do some loading thing here -->
            <div class="loading" v-if="isLoading">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
            <!-- / Do some loading thing here -->

            <!-- Approval Details -->
            <v-row v-if="!isLoading && investor" class="mb-3">
                <v-col cols="12">
                    <approval-details :investor="investor"></approval-details>
                </v-col>

            </v-row>
            <!-- Approval Details -->

            <!-- Approve confirm modal -->
            <v-dialog v-model="attemptingToApprove" persistent max-width="600px">
                <v-card>
                    <v-card-title class="text-h5 mb-4">Confirm Approval</v-card-title>
                    <v-card-text>
                        Are you sure you want to approve <b>{{ investor ? investor.name : 'Unknown' }}</b>?
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="isApproving" color="primary darken-1" text
                            @click="attemptingToApprove = null">
                            Cancel
                        </v-btn>
                        <v-btn :disabled="isApproving" :loading="isApproving" color="green darken-1" text
                            @click="attemptApprove">
                            Yes, Approve!
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- / Approve confirm modal -->
        </v-container>
    </div>
</template>

<script>
import investors from "../../../../api/investors";
import ApprovalDetails from "./Show/ApprovalDetails.vue";
export default {
    data() {
        return {
            isLoading: false,
            investor: null,

            attemptingToApprove: false,
            isApproving: false
        }
    },

    components: { ApprovalDetails },

    created() {
        this.loadInvestor();
    },

    methods: {
        goBack() {
            return this.$router.push('/approvals');
        },

        loadInvestor() {
            let vm = this;
            vm.isLoading = true;
            vm.investor = null;
            investors
                .get(this.investorId)
                .then((r) => {
                    vm.isLoading = false;
                    vm.investor = r.data;
                })
                .catch((e) => {
                    console.log(e);
                    vm.$toast.error("Investor not found");
                    vm.$router.push("/investors");
                });
        },

        attemptApprove() {
            let vm = this;
            vm.isApproving = true;
            investors.approve(this.investor.id).then(r => {
                vm.isApproving = false;
                vm.$toast.success("Consignor approved successfully");
                vm.$router.push('/investors/' + this.investor.id);
            }).catch(e => {
                console.log(e);
                vm.isApproving = false;
                vm.$toast.error('Error approving consignor. Please try again.');
            });
        }
    },

    computed: {
        investorId() {
            return this.$route.params.id;
        },

        endpoint() {
            return "investors/" + this.investorId;
        },

        title() {
            if (this.isLoading) {
                return "Loading...";
            }
            if (this.investor) {
                return this.investor.name;
            }
            return "Unknown";
        },
    }
}
</script>

<style scoped lang="scss">
.loading {
    text-align: center;
    padding-top: 20px;
}

.approvals-show {
    padding: 20px;

    .welcome {
        margin-bottom: 20px;
        display: flex;
        justify-items: center;

        h1 {
            margin-left: 10px;
            flex-grow: 1;
        }

        .actions {
            flex-shrink: 1;
            display: flex;
            justify-items: center;
            align-items: center;
        }
    }
}
</style>